export const validClients = [
  "xsso_above_constraints",
  "xsso_constraints",
  "xsso_spaceops",
];

export const Redirects: { [key: string]: string } = {
  xsso_above_constraints: "https://aboveconstraints.com",
  xsso_constraints: "https://playconstraints.com",
  xsso_spaceops: "https://spaceops.aboveconstraints.com",
};

export const Banners: { [key: string]: string } = {
  xsso_above_constraints:
    "url('https://rare-gallery.com/uploads/posts/130810-countess-paragon.jpg')",
  xsso_constraints:
    "url('https://rare-gallery.com/uploads/posts/130810-countess-paragon.jpg')",
  xsso_spaceops:
    "url('http://blog.xcibe95x.com/wp-content/uploads/2022/03/Space-Ops-Arcade.jpg')",
};
