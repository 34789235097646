import styles from "./Register.module.css";
import usePostRequest from "../../hooks/PostRequest";
import usePasswordValidator from "../../hooks/PasswordValidation";
import { Button } from "../../components/General/Button/Button";
import { Input } from "../../components/Input/Input";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CheckList } from "../../components/General/CheckList/CheckList";
import { Title } from "../../components/Title/Title";
import { motion } from "framer-motion";

// Above Constraints Centralized Account Registration

const Register = () => {
  const api = "https://pbe.playconstraints.com/xsso/auth/register.php";

  // Hooks
  const Navigate = useNavigate();
  const { Post, loading, error } = usePostRequest();
  const validate = usePasswordValidator();
  // Active requirement visualization
  const [step, setStep] = useState<number>(0);
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  // Required Registration Data
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordCheck, setPasswordCheck] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  // Validate the current registration step
  const handleStepValidation = () => {
    let isValid = false; // Declare a boolean to unlock the continue button
    // Form Data Validation
    switch (step) {
      case 0:
        if (email.length > 0) isValid = true;
        break;
      case 1:
        if (birthday.length > 0) isValid = true;
        break;
      case 2:
        if (
          username.length > 0 &&
          password.length > 0 &&
          passwordCheck.length > 0 &&
          password === passwordCheck
        )
          isValid = true;
        break;
    }
    setCanContinue(isValid);
  };

  const handleSubmit = (e: any) => {
    setLoading(true);
    e.preventDefault();
    Post(api, {
      username: username,
      password: password,
      email: email,
      birthday: birthday,
    });
  };

  // Make sure that all the fields have been
  // filled so we can unlock the signup button
  useEffect(() => {
    handleStepValidation();
  }, [username, password, birthday, email, passwordCheck]);

  // Observe Post Request Status
  useEffect(() => {
    if (!loading && !error) Navigate("/");
    if (error) {
      Navigate("/signup?error=500");
      setLoading(false);
    }
  }, [loading, error]);

  const emailStep = () => {
    return (
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <Input
          id={"email"}
          type={"email"}
          label={"Email"}
          placeholder={"Email"}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          disabled={!canContinue}
          style={{ fontWeight: "500" }}
          onClick={() => setStep(step + 1)}
        >
          Continue
        </Button>
      </motion.div>
    );
  };

  const birthDayStep = () => {
    return (
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <Input
          id={"birthday"}
          type={"date"}
          label={"Birthday"}
          placeholder={"Birthday"}
          required
          onChange={(e) => setBirthday(e.target.value)}
        />
        <Button
          disabled={!canContinue}
          style={{ fontWeight: "500" }}
          onClick={() => setStep(step + 1)}
        >
          Continue
        </Button>
      </motion.div>
    );
  };

  const accountStep = () => {
    return (
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <Input
          label={"Username"}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          type={"password"}
          label={"Password"}
          required
          onChange={(e) => setPassword(e.target.value)}
          valid={
            validate(password).isValid && password === passwordCheck
              ? true
              : false
          }
          invalid={
            validate(password).isValid && password !== passwordCheck
              ? true
              : false
          }
        />
        <ul hidden={validate(password).isValid}>
          <CheckList
            status={validate(password).minLength}
            label="Atleast 8 characters"
          ></CheckList>
          <CheckList
            status={validate(password).hasCharacter}
            label="Atleast one character and one number"
          ></CheckList>
          <CheckList
            status={validate(password).hasSpecialChar}
            label="Atleast one special character"
          ></CheckList>
        </ul>
        <Input
          type={"password"}
          label={"Confirm Password"}
          placeholder={"Confirm Password"}
          required
          hidden={!validate(password).isValid}
          onChange={(e) => setPasswordCheck(e.target.value)}
          valid={passwordCheck === password ? true : false}
          invalid={passwordCheck !== password ? true : false}
        />

        {/* SIGN UP BUTTON Send in the post request*/}
        <Button
          disabled={!canContinue}
          style={{ fontWeight: "500" }}
          type="submit"
          loading={isLoading}
        >
          Sign Up
        </Button>
      </motion.div>
    );
  };

  return (
    <div className={styles.container}>
      <Title>Create Account</Title>
      <motion.div
        className={styles.loginForm}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className={styles.brand}>
          <img
            className={styles.logo}
            src="/above.png"
            alt="Above Constraints"
          ></img>
          <h1>Above Constraints</h1>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Email Form */}

          {step === 0 && emailStep()}
          {step === 1 && birthDayStep()}
          {step === 2 && accountStep()}
          {/* <Button disabled={true} style={{ fontWeight: "500" }} type="submit">
            Sign Up
          </Button> */}
        </form>
        <div className={styles.usefulLinks}>
          <Link to={"/"}>Already have an account?</Link>
        </div>
      </motion.div>
    </div>
  );
};

export default Register;
