type maintenance = {
  children: React.ReactElement;
  isEnabled: boolean;
  page?: JSX.Element;
  excludePath?: string[];
};

export const MaintenanceProvider: React.FC<maintenance> = ({
  children,
  isEnabled,
  page,
  excludePath,
}) => {
  switch (isEnabled as boolean) {
    case false:
      return children;

    case true:
      console.log(excludePath);
      if (!excludePath?.includes(window.location.pathname)) {
        if (page) {
          return page;
        } else {
          return (
            <div>
              <h1>WEBSITE IS CURRENTLY UNDER MAINTENANCE</h1>
            </div>
          );
        }
      } else {
        return children;
      }
  }
};
