import { IInput } from "./IInput";
import styles from "./Input.module.css";
import cn from "classnames";

export const Input: React.FC<IInput> = ({ ...props }) => {
  return (
    <div className={styles.inputField}>
      <label hidden={true} htmlFor={props.id}>
        {props.label}
      </label>
      <input
        {...props}
        id={props.label}
        placeholder={props.label}
        className={cn(
          styles.input,
          props.valid && styles.valid,
          props.invalid && styles.invalid
        )}
        type={props.type ? props.type : "text"}
      ></input>
    </div>
  );
};
