const usePasswordValidator = () => {
  const validate = (password: string) => {
    let isValid = false;
    const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

    const minLength = password.length > 8;
    const hasCharacter = password.length > 1 && /\d/.test(password);
    const hasSpecialChar = format.test(password);

    if (minLength && hasCharacter && hasSpecialChar) isValid = true;

    // Boolean to ""Byte"" its a number
    const b2b = (value: boolean) => {
      if (password.length <= 0) return 3;
      if (value) {
        return 0;
      } else {
        return 1;
      }
    };

    return {
      isValid: isValid,
      minLength: b2b(minLength),
      hasCharacter: b2b(hasCharacter),
      hasSpecialChar: b2b(hasSpecialChar),
    };
  };

  return validate;
};

export default usePasswordValidator;
