import ReactDOM from "react-dom/client";
import { MaintenanceProvider } from "./components/Maintenance/Maintenance";
import MaintenancePage from "./pages/Maintenance/Maintenance";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { maintenance } from "./config";
import AboveApp from "./AboveApp";
import Register from "./authentication/Register/Register";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MaintenanceProvider isEnabled={maintenance} page={<MaintenancePage />}>
    <BrowserRouter>
      <Routes>
        {/* <Route path="*" element={<PageNotFound />} /> */}
        <Route path="/" element={<AboveApp />}></Route>
        <Route path="/signup" element={<Register />}></Route>
      </Routes>
    </BrowserRouter>
  </MaintenanceProvider>
);
