import { useEffect } from "react";
import Error from "./authentication/Error/Error";
import Login from "./authentication/Login/Login";
import useGET from "./hooks/GetRequest";
import { validClients } from "./request/Clients";
import "./style.css";
import { getParam } from "./utilities/GetUrlParams";

function AboveApp() {
  const request = useGET();

  useEffect(() => {
    // console.log(getParam("client_id"));
    // setTimeout(
    //   () => request.get("https://pbe.playconstraints.com/v2/fake_login.php"),
    //   2000
    // );
    if (request.completed) {
      console.log(request.json);
    }
  }, [request.completed]);

  // Check if app id provided in the URL is valid
  const hasValidAppID = () => {
    const AppID = getParam("client_id") as string;
    const isValid = validClients.includes(AppID);
    return isValid;
  };

  return (
    <div className="AboveApp">{hasValidAppID() ? <Login /> : <Error />}</div>
  );
}

export default AboveApp;
