import { Spinner } from "../Spinner/Spinner";
import styles from "./Button.module.css";
import { IButton } from "./IButton";

export const Button: React.FC<IButton> = ({ ...props }) => {
  return (
    <>
      <button
        {...props}
        className={styles.btn}
        type={props.type ? props.type : "button"}
        disabled={props.disabled || props.loading}
      >
        {props.children}
        {props.loading && <Spinner />}
      </button>
    </>
  );
};
