import axios from "axios";
import { useState } from "react";

const useGET = () => {
  const [json, setJson] = useState();
  const [completed, setCompleted] = useState(false);

  const GET = async (url: string) => {
    try {
      const { data } = await axios.get(url, {
        withCredentials: true,
      });
      //   console.log(data);
      setJson(data);
      setCompleted(true);
    } catch (error) {
      console.error(error);
    }
  };

  return { get: GET, completed: completed, json: json };
};

export default useGET;
