import axios, { AxiosResponse, AxiosResponseHeaders } from "axios";
import { useState } from "react";
import { Redirects } from "../request/Clients";
import { getParam } from "../utilities/GetUrlParams";

const usePostRequest = () => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<AxiosResponse>();
  const [headers, setHeaders] = useState<AxiosResponseHeaders | any>();

  const Post = async (url: string, dataset: {}) => {
    try {
      let postData = new FormData();

      Object.entries(dataset).forEach((entry) => {
        const [key, value] = entry;
        postData.append(key, value as string | Blob);
      });

      const { data: response, headers } = await axios.post(url, postData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      console.log(response);
      const AppID = getParam("client_id") as string;
      console.log("Applet: ", Redirects[AppID]);
      window.open(Redirects[AppID], "_self");
      localStorage.setItem("accessToken", response.accessToken);
      console.log(localStorage.getItem("lastname"));
      setLoading(false);
      setResponse(response);
      setHeaders(headers);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  return {
    Post,
    loading,
    error,
    headers,
    response,
  };
};

export default usePostRequest;
