import { Title } from "../../components/Title/Title";
import styles from "./Maintenance.module.css";

const MaintenancePage = () => {
  return (
    <div className={styles.body}>
      <Title>AboveCS: Maintenance</Title>
      <div className={styles.flex}>
        <img src="/above.png" alt="Above Constraints"></img>
        <h1>COMING SOON</h1>
        <p>aboveconstraints.com</p>
      </div>
    </div>
  );
};

export default MaintenancePage;
