import styles from "./CheckList.module.css";

export const CheckList = (props: { status?: number; label?: string }) => {
  // Define a Visual State of the Commponent if Success | Error | Default

  const state = () => {
    switch (props.status) {
      case 0:
        return styles.success;
      case 1:
        return styles.error;
      default:
        return styles.default;
    }
  };

  const circle = () => {
    switch (props.status) {
      case 0:
        return styles.circleSuccess;
      case 1:
        return styles.circleError;
      default:
        return styles.circleDefault;
    }
  };

  return (
    <li className={styles.listitem}>
      <div className={circle()}>
        <div className={state()}></div>
      </div>
      <p>{props.label}</p>
    </li>
  );
};
