import styles from "./Login.module.css";
import { Button } from "../../components/General/Button/Button";
import { Input } from "../../components/Input/Input";
import usePostRequest from "../../hooks/PostRequest";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Title } from "../../components/Title/Title";
import { motion } from "framer-motion";
import { Banners } from "../../request/Clients";
import { getParam } from "../../utilities/GetUrlParams";

const Login = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { Post } = usePostRequest();

  const AppID = getParam("client_id") as string;

  return (
    <div className={styles.container}>
      <Title>Sign In</Title>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <div
          className={styles.banner}
          style={{
            backgroundImage: Banners[AppID],
          }}
        ></div>
        <div className={styles.loginForm}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log("Submitted");
            }}
          >
            <Input
              onChange={(e) => {
                setUsername(e.currentTarget.value);
              }}
              label="Username"
            />
            <Input
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              type="password"
              label="Password"
            />
            <Button
              onClick={() =>
                Post(
                  "https://xsso.aboveconstraints.com/index.php",
                  {
                    username: username,
                    password: password,
                  }
                )
              }
              type="submit"
            >
              Sign In
            </Button>
          </form>
          <div className={styles.usefulLinks}>
            <Link to={""}>Can't sign in?</Link>
            <Link to={"/signup"}>Create Account</Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;
