import styles from "./Spinner.module.css";

export const Spinner = () => {
  return (
    <div data-spinner={true} className={styles["lds-ring"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
