import styles from "../Login/Login.module.css";
import { Title } from "../../components/Title/Title";
import { motion } from "framer-motion";

const Error = () => {
  return (
    <div className={styles.container}>
      <Title>Something is Wrong</Title>
      <motion.div
        className={styles.loginForm}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className={styles.brand}>
          <img
            className={styles.logo}
            src="/above.png"
            alt="Above Constraints"
          ></img>
          <h1>Above Constraints</h1>
        </div>

        <form>
          <h3>Something went wrong</h3>
          <p>Invalid request.</p>
        </form>
      </motion.div>
    </div>
  );
};

export default Error;
